(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("neo4j-driver"), require("vis/dist/vis-network.min"), require("core-js/modules/es.symbol"), require("core-js/modules/es.symbol.description"), require("core-js/modules/es.symbol.iterator"), require("core-js/modules/es.array.iterator"), require("core-js/modules/es.object.define-property"), require("core-js/modules/es.object.to-string"), require("core-js/modules/es.string.iterator"), require("core-js/modules/web.dom-collections.iterator"), require("core-js/modules/es.array.concat"), require("core-js/modules/es.array.for-each"), require("core-js/modules/es.array.map"), require("core-js/modules/es.function.name"), require("core-js/modules/es.object.values"), require("core-js/modules/es.promise"), require("core-js/modules/web.dom-collections.for-each"), require("core-js/modules/web.timers"), require("regenerator-runtime/runtime"), require("vis/dist/vis-network.min.css"));
	else if(typeof define === 'function' && define.amd)
		define(["neo4j-driver", "vis/dist/vis-network.min", "core-js/modules/es.symbol", "core-js/modules/es.symbol.description", "core-js/modules/es.symbol.iterator", "core-js/modules/es.array.iterator", "core-js/modules/es.object.define-property", "core-js/modules/es.object.to-string", "core-js/modules/es.string.iterator", "core-js/modules/web.dom-collections.iterator", "core-js/modules/es.array.concat", "core-js/modules/es.array.for-each", "core-js/modules/es.array.map", "core-js/modules/es.function.name", "core-js/modules/es.object.values", "core-js/modules/es.promise", "core-js/modules/web.dom-collections.for-each", "core-js/modules/web.timers", "regenerator-runtime/runtime", "vis/dist/vis-network.min.css"], factory);
	else if(typeof exports === 'object')
		exports["NeoVis"] = factory(require("neo4j-driver"), require("vis/dist/vis-network.min"), require("core-js/modules/es.symbol"), require("core-js/modules/es.symbol.description"), require("core-js/modules/es.symbol.iterator"), require("core-js/modules/es.array.iterator"), require("core-js/modules/es.object.define-property"), require("core-js/modules/es.object.to-string"), require("core-js/modules/es.string.iterator"), require("core-js/modules/web.dom-collections.iterator"), require("core-js/modules/es.array.concat"), require("core-js/modules/es.array.for-each"), require("core-js/modules/es.array.map"), require("core-js/modules/es.function.name"), require("core-js/modules/es.object.values"), require("core-js/modules/es.promise"), require("core-js/modules/web.dom-collections.for-each"), require("core-js/modules/web.timers"), require("regenerator-runtime/runtime"), require("vis/dist/vis-network.min.css"));
	else
		root["NeoVis"] = factory(root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined], root[undefined]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__19__) {
return 